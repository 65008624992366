import {
  CSSObject,
  GlobalStyles,
  ThemeProvider as MUIThemeProvider,
} from "@mui/material";
import { memo, PropsWithChildren, useMemo } from "react";
import { createTheme, Theme as MuiTheme } from "@mui/material";
import * as color from "@mui/material/colors";

export interface ProjectTheme {
  fontSize: {
    xxs: number;
    xs: number;
    sm: number;
    basic: number;
    md: number;
    lg: number;
    xl: number;
    xxl: number;
  };
  ellipsis: (lines: number) => CSSObject;
  color: typeof color;
  invisibleScrollBar: CSSObject;
}

export const ThemeProvider: React.FC<PropsWithChildren> = memo((props) => {
  const { theme } = useTheme();

  return (
    <MUIThemeProvider theme={theme}>
      <GlobalStyledComponent />
      {props.children}
    </MUIThemeProvider>
  );
});

const useTheme = () => {
  const projectTheme = useMemo(
    (): ProjectTheme => ({
      fontSize: {
        xxs: 10.5,
        xs: 12,
        sm: 13.5,
        basic: 16,
        md: 18,
        lg: 20,
        xl: 24,
        xxl: 30,
      },
      ellipsis: (lines: number) => {
        const num = String(lines).replace("px", "");
        return {
          whiteSpace: "pre-line",
          display: "-webkit-box",
          wordBreak: "break-all",
          WebkitLineClamp: num,
          WebkitBoxOrient: "vertical",
          overflow: "hidden",
        };
      },
      color,
      invisibleScrollBar: {
        msOverflowStyle: "none",
        scrollbarWidth: "none",
        "::-webkit-scrollbar": {
          display: "none",
        },
      },
    }),
    []
  );

  const muiTheme: MuiTheme = useMemo(
    () =>
      createTheme({
        palette: {
          primary: {
            main: "#18222B",
          },
          secondary: {
            main: "rgba(200, 200, 200)",
          },
        },
        zIndex: {},
        components: {
          MuiContainer: {
            defaultProps: {
              maxWidth: "md",
            },
          },
          MuiTypography: {
            styleOverrides: {
              root: {
                lineHeight: 2,
                fontWeight: "bolder",
                whiteSpace: "pre-line",
              },
            },
          },
          MuiInputBase: {
            styleOverrides: {
              root: {
                backgroundColor: "white",
              },
            },
          },
        },
        shape: {
          borderRadius: 8,
        },
      }),
    []
  );

  const theme: MuiTheme = useMemo(
    () => ({
      ...muiTheme,
      ...projectTheme,
    }),
    [muiTheme, projectTheme]
  );

  return { theme };
};

const GlobalStyledComponent = () => (
  <GlobalStyles
    styles={{
      "html ,body": {
        margin: 0,
        padding: 0,
        height: "100%",
        "#__next": {
          height: "100%",
        },
        "@supports (-webkit-touch-callout: none)": {
          height: "-webkit-fill-available",
        },
      },
      body: {
        margin: 0,
        padding: 0,
        height: "100%",
      },

      "*": {
        boxSizing: "border-box",
        fontFamily: "'Hina Mincho', serif !important",
      },
    }}
  />
);

declare module "@mui/material" {
  export interface Theme extends ProjectTheme {}
}
