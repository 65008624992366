import { Alert, Snackbar } from "@mui/material";
import { createContext, memo, PropsWithChildren, useState } from "react";

interface ContextValue {
  setSuccess: (message: string) => void;
  setInfo: (message: string) => void;
  setAlert: (message: string) => void;
  setWarning: (message: string) => void;
}

export const SnackbarContext = createContext({} as ContextValue);

export const SnackbarProvider: React.FC<PropsWithChildren> = memo((props) => {
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [infoMessage, setInfoMessage] = useState<string | null>(null);
  const [warningMessage, setWarningMessage] = useState<string | null>(null);
  const [alertMessage, setAlertMessage] = useState<string | null>(null);

  return (
    <SnackbarContext.Provider
      value={{
        setSuccess: setSuccessMessage,
        setInfo: setInfoMessage,
        setWarning: setWarningMessage,
        setAlert: setAlertMessage,
      }}
    >
      {props.children}

      {/* Success Message */}
      <Snackbar
        open={Boolean(successMessage)}
        autoHideDuration={5000}
        onClose={() => setSuccessMessage(null)}
      >
        <Alert severity="success">{successMessage}</Alert>
      </Snackbar>

      {/* Info Message */}
      <Snackbar
        open={Boolean(infoMessage)}
        autoHideDuration={5000}
        onClose={() => setInfoMessage(null)}
      >
        <Alert severity="info">{infoMessage}</Alert>
      </Snackbar>

      {/* Warning Message */}
      <Snackbar
        open={Boolean(warningMessage)}
        autoHideDuration={5000}
        onClose={() => setWarningMessage(null)}
      >
        <Alert severity="warning">{warningMessage}</Alert>
      </Snackbar>

      {/* Error Message */}
      <Snackbar
        open={Boolean(alertMessage)}
        autoHideDuration={5000}
        onClose={() => setAlertMessage(null)}
      >
        <Alert severity="error">{alertMessage}</Alert>
      </Snackbar>
    </SnackbarContext.Provider>
  );
});
