import { AppProps } from "next/app";
import { ThemeProvider } from "../providers/theme.provider";
import { SnackbarProvider } from "@/providers/snackbar-context.provider";
import { ClientProvider } from "@/providers/client-provider";
import { FontAwesomeIconProvider } from "@/providers/font-awesome-provider";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

function CustomApp({ Component, pageProps }: AppProps) {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <ClientProvider>
        <FontAwesomeIconProvider>
          <ThemeProvider>
            <SnackbarProvider>
              <Component {...pageProps} />
            </SnackbarProvider>
          </ThemeProvider>
        </FontAwesomeIconProvider>
      </ClientProvider>
    </LocalizationProvider>
  );
}

export default CustomApp;
