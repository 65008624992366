import { Fragment, memo, PropsWithChildren, useEffect, useState } from "react";

export const ClientProvider: React.FC<PropsWithChildren> = memo((props) => {
  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    setIsClient(true);
  }, []);

  return <Fragment>{isClient && props.children}</Fragment>;
});
