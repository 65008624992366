import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { Fragment, memo, PropsWithChildren } from "react";

interface Props {}
export const FontAwesomeIconProvider: React.FC<PropsWithChildren<Props>> = memo(
  (props) => {
    library.add(fab, fas, far);
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <Fragment>{props.children}</Fragment>;
  }
);
